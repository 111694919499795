// Viz settings
export let defaultState = {
  M0: 100,
  M1: 100,
  SD: 15,
  cohend: 0.8,
  U3: 0,
  propOverlap: 0,
  CER: 20,
  NNT: 0,
  CL: 0,
  xLabel: "default_outcome_translate",
  muZeroLabel: "default_control_translate",
  muOneLabel: "default_treatment_translate",
  sliderMax: 2,
  sliderStep: 0.01,
  colorDist1: { r: 48, g: 57, b: 79, a: 1 },
  colorDistOverlap: { r: 0, g: 0, b: 0, a: 1 },
  colorDist2: { r: 106, g: 206, b: 235, a: 1 },
  immediate: false,
  preset: "large",
  slug: "",
};
